import * as React from "react";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../../../utils/axiosHelper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState, useEffect } from "react";
import ConfirmationDialog from "../../../../components/confirmationPopup";
import RiskWrapper, {
  RiskColorWrapper,
} from "../../../../components/overview/riskWrapper";
import CommonSettingTable from "../../../../components/table/commonSettingTable";
import { matricRisk, riskMap } from "../../../../utils/levelMap";
import { useParams, useHistory } from "react-router-dom";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import SelectField from "../../../../components/select/selectField";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function MatricLevelRisk({ classes }) {
  // const classes = useStyles();
  const { settingTab } = useParams();
  const history = useHistory();
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState();

  const [riskValue, setRiskValue] = useState();
  const [deleteRisk, setDeleteRisk] = useState();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  console.log(location.state, "location");

  const columns = [
    {
      field: "DisplayName",
      title: "Metric",
    },
    {
      field: "From.$numberDecimal",
      title: "Target Range: Greater Than/Equal To",
      render: (rowData) => (
        <div
          style={{
            color:
              rowData?.From &&
              parseInt(rowData?.From?.$numberDecimal) < 0 &&
              "red",
          }}
        >
          {rowData?.From?.$numberDecimal}
          {rowData?.From?.$numberDecimal ? "%" : <AllInclusiveIcon />}
        </div>
      ),
    },
    {
      field: "To.$numberDecimal",
      title: "Target Range: Less Than",
      render: (rowData) => (
        <div
          style={{
            color: rowData?.To
              ? parseInt(rowData?.To?.$numberDecimal) < 0 && "red"
              : "",
          }}
        >
          {rowData?.To?.$numberDecimal}
          {rowData?.To?.$numberDecimal ? "%" : <AllInclusiveIcon />}
        </div>
      ),
    },
    // {
    //   field: "RiskScore.$numberDecimal",
    //   title: "Risk Score",
    // },
    {
      field: "RiskLevel",
      title: "Risk Level",
      render: (rowData) =>
        // <center>
        rowData.DisplayName === "Capacity Accuracy" ? (
          <RiskColorWrapper riskLevel={matricRisk[rowData.RiskLevel]}>
            {matricRisk[rowData.RiskLevel]}
          </RiskColorWrapper>
        ) : (
          <RiskWrapper>{riskMap[rowData.RiskLevel]}</RiskWrapper>
        ),
      // </center>
    },
    {
      field: "Actions",
      title: "Actions",
      width: "150px",
      render: (row) => (
        <Grid item xs={4} sx={{ ml: 3 }}>
          <EditIcon
            className={classes.RiskIcons}
            onClick={() => {
              history.push({
                pathname: `/settings/${settingTab}/form/metricLevel/${project?.value}`,
                state: { row },
              });
            }}
            style={{ cursor: "pointer", color: "#5EBDCC" }}
          />
          <DeleteOutlinedIcon
            className={classes.RiskIcons}
            style={{ cursor: "pointer" }}
            xs={6}
            sx={{ ml: 3 }}
            onClick={() => {
              setOpen(true);
              setDeleteRisk(row);
            }}
          />
        </Grid>
      ),
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  // get List
  const getList = () => {
    if (!project?.value) return;
    axios
      .get(`/api/risks?ProjectId=${project?.value}`)
      .then((result) => {
        setRiskValue(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // add page handler
  const AddEditRisks = (projectid) => {
    history.push(`/settings/${settingTab}/form/metricLevel/${projectid}`);
  };
  // delete Handler
  const deleteconRisk = () => {
    axios.delete(`/api/risks/delete/${deleteRisk.RiskId}`).then((result) => {
      setOpen(false);
      getList();
    });
  };

  const getProject = () => {
    let clientId = localStorage.getItem("ClientId");
    axios
      .get(`/api/sprint/bargraph/getProjects/${clientId}`)
      .then((response) => {
        if (!response.data) return;
        let data = response.data?.map((e) => {
          return {
            value: e["ProjectId"],
            lable: e["ProjectName"],
          };
        });
        console.log(data, "data");
        setProjectList(data);
        const selectedProject =
          data?.find((e) => {
            return e?.value == location?.state;
          }) || data[0];
        setProject(selectedProject);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProject();
  }, []);

  useEffect(() => {
    getList();
  }, [project?.value]);

  const filters = (
    <>
      <div>
        <SelectField
          render={projectList}
          value={project}
          setValue={(value) => {
            setProject(value);
          }}
          name={"ProjectList"}
          label="Project List"
          withLable={true}
        />
      </div>
    </>
  );

  return (
    <>
      <CommonSettingTable
        columns={columns}
        clickHandler={() => AddEditRisks(project?.value)}
        btnLabel={"New Metric Level Risks"}
        data={riskValue}
        tableTitle={""}
        children={filters}
      ></CommonSettingTable>
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={"Delete"}
        text={"Are you sure you want to delete?"}
        deleteHandler={deleteconRisk}
      />
    </>
  );
}
