import React from "react";
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
// import Tabs from "@mui/material/Tabs";
import kpiDoc from "../../assets/kpiDoc.png";
import teamsLogo from "../../assets/teamsLogo.png";
import axios from "../../utils/axiosHelper";

// import {
//   PieChart,
//   Pie,
//   Sector,
//   Cell,
//   ResponsiveContainer,
//   Legend,
//   // BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Label,
//   LabelList,
// } from "recharts";
import { useEffect, useState, useRef } from "react";
// import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
// import Chip from "@mui/material/Chip";
// import Stack from "@mui/material/Stack";
import CommonTablePopup from "../models/commonTablePopup";
import BarChart from "../../pages/Project/projectDashboard/graphs/barChart";
import WorkAlocationChart from "../../pages/Project/projectDashboard/graphs/workAlocationChart";
import { exportComponentAsPNG } from "react-component-export-image";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(() => ({
  root: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
    margin: "20px",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#5EBDCC",
      color: "white",
    },
    width: "290px",
    // height: "130px",
  },
  rootCard: {
    height: "100%",
  },
  chip: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "right",
    backgroundColor: "white",
    fontSize: "20px",
    fontWeight: "bold",
  },
  icon: {
    backgroundColor: "#eff8fa",
    width: "35px",
    height: "35px",
    "border-radius": "50%",
    // justifyContent: "center",
    // display: "flex",
    alignItems: "center",
    "& img": {
      maxWidth: "15px",
      maxHeight: "15px",
    },
  },
  textContianer: {
    "& h6": {
      marginBottom: "0px",
    },
  },
  sideCard: {
    display: "flex",
    alignItems: "center",
    gap: "22px",
    padding: "20px",
    cursor: "pointer",
    "& span": {
      font: "normal normal normal 16px/20px Lexend Deca",
      color: "#5EBDCC",
      opacity: 1,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    height: "50px",
  },
  customCard: {
    height: "50px",
    boxShadow: "none",
    backgroundColor: "white",
  },
  graphCard: {
    marginTop: "20px",
  },
}));
// const COLORS = ["#2DB9FF", "#FF7DA1", "#EFDA44", "#455BEA"];

// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index,
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? "start" : "end"}
//       dominantBaseline="central"
//     >
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };
const SprintPieCharts = (props) => {
  const classes = useStyles();
  const {
    dashBoardgraphData,
    sprintTitleDate,
    jiraTableData,
    setSprintId,
    sprintId,
    setMatricId,
    setDataItem,
    dataItem,
    setGraphIndex,
    selectedSprintId,
    setIsSprintSpillover,
  } = props;
  const [dashboardData, setDashBoardData] = useState([]);
  const [teamList, setTeamList] = useState({
    columnsList: [],
    data: [],
  });
  const history = useHistory();

  const [sprintSpelloverData, setSprintSpellOverData] = useState([]);
  const [WorkCommitmentDat, setWorkCommitmentData] = useState([]);

  const [noOfKpis, setNoOfKpis] = useState(3);
  const [isOpenTeam, setIsOpenTeam] = useState(false);

  // const kpiHandle = () => {};
  // const pieData = dashBoardgraphData?.map((e) => e["Work Commitment"])[0];
  // console.log(dashBoardgraphData, "dashBoardgraphData");

  const pieRef = useRef("");
  const handlePieDownload = () => {
    exportComponentAsPNG(pieRef, { fileName: "pie-chart.png" });
  };

  const getData = (data, index) => {
    setDataItem(data);
    setSprintId(dashBoardgraphData?.map((e) => e[data])[0][0].SprintId);
    setMatricId(dashBoardgraphData?.map((e) => e[data])[0][0].MetricId);
    setGraphIndex(index);

    // this is for show sprint spillover data flag
    setIsSprintSpillover(false);
  };

  // const transformedArray = [];

  // const firstObject = pieData?.[0];

  // for (const key in firstObject) {
  //   if (
  //     key !== "_id" &&
  //     key !== "BoardId" &&
  //     key !== "GroupId" &&
  //     key !== "GroupTypeId" &&
  //     key !== "MetricId" &&
  //     key !== "SprintId" &&
  //     key !== "__v"
  //   ) {
  //     transformedArray.push({ title: key, value: firstObject[key] });
  //   }
  // }

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevInitialData = usePrevious(dashBoardgraphData);

  useEffect(() => {
    const convertData = () => {
      if (dashBoardgraphData) {
        const allValues = [];
        for (const item of dashBoardgraphData) {
          for (const key in item) {
            if (
              item.hasOwnProperty(key) &&
              Array?.isArray(item[key]) &&
              item[key]?.length > 0
            ) {
              const entry = item[key][0];
              if (entry?.hasOwnProperty("Value")) {
                allValues?.push({ name: key, value: entry.Value });
              }
            }
          }
        }
        setDashBoardData(allValues);
      }
    };
    if (dashBoardgraphData !== prevInitialData) {
      convertData();
    }
  }, [dashBoardgraphData, dashboardData, prevInitialData]);

  // get team List api
  const getTeamList = () => {
    axios
      .get(`/api/v2/sprints/teamMember/${selectedSprintId}`)
      .then((res) => {
        // columnsList: [],
        // data: [],
        const data = { ...teamList };
        data.columnsList = [
          {
            field: "Name",
            title: "Name",
            width: "150px",
            render: (rowData) => (
              <div
                style={{ cursor: "pointer", color: "#5EBDCC" }}
                onClick={() =>
                  // history.push(
                  //   `/project/resource/:projectId?name=${rowData.Name}&matric=All&projectName=sprint`
                  // )
                  history.push({
                    pathname: `/project/${rowData.ProjectId}/single-resource/${rowData?.Id}`,
                    state: {
                      projectName: `Sprint`,
                      resourceName: rowData?.Name,
                    },
                  })
                }
              >
                {rowData.Name}
              </div>
            ),
          },
          {
            field: "taskAssigned",
            title: "Task Assigned",
            width: "100px",
          },
          {
            title: "Task Completed",
            field: "taskCompleted",
            width: "100px",
          },
          {
            title: "Work Forcasted",
            field: "workForcasted",
            width: "100px",
          },
        ];
        data.data = res.data;
        setTeamList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSprintSpelloverData = () => {
    axios
      .get(`/api/v2/sprints/spillover/${selectedSprintId}`)
      .then((res) => {
        if (res.data) {
          const data = Object.keys(res.data).map((e) => {
            return { work: e, value: res.data[e] };
          });
          setSprintSpellOverData(data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // getTeamList();
    getSprintSpelloverData();
  }, [selectedSprintId]);
  useEffect(() => {
    if (dashBoardgraphData.length > 0) {
      const chartData = [];
      const WorkCommitment = dashBoardgraphData[0]["Work Commitment"][0];
      Object?.keys(WorkCommitment)?.forEach((item) => {
        if (
          item !== "_id" &&
          item !== "BoardId" &&
          item !== "GroupId" &&
          item !== "GroupTypeId" &&
          item !== "MetricId" &&
          item !== "SprintId" &&
          item !== "__v"
        ) {
          chartData.push({ title: item, value: WorkCommitment[item] });
        }
      });
      setWorkCommitmentData(chartData);
    }
  }, [dashBoardgraphData]);
  useEffect(() => {
    if (sprintId !== undefined) {
      jiraTableData(dataItem);
      sprintTitleDate(dataItem);
    }
  }, [dataItem, sprintId]);

  // const data = [{ "Work committed": 100, "work not done": 75 }];

  // const yAxisTicks = Object.keys(data[0]).filter((key) => key !== "category");

  // const renderCustomBarLabel = ({ x, y, width, value }) => {
  //   const percentage = `${Math.round(
  //     (value / data[0]["Work committed"]) * 100
  //   )}%`;
  //   const displayValue =
  //     value === data[0]["Work committed"] ? "100%" : percentage;
  //   return (
  //     <text
  //       x={x + width / 2}
  //       y={y + 20}
  //       fill="#fff"
  //       textAnchor="middle"
  //       dominantBaseline="middle"
  //     >
  //       {displayValue}
  //     </text>
  //   );
  // };
  const iconColors = ["skyblue", "red", "orange", "green", "blue"];
  const icons = [
    "fa fa-archive",
    "fas fa-exclamation-triangle",
    "fa fa-clock-o",
    "fa fa-check-circle",
    "fa fa-rotate-left",
  ];
  const teamModelHander = () => {
    getTeamList();
    setIsOpenTeam(true);
  };

  return (
    <>
      <div className="row d-flex justify-content-center align-items-center">
        <div
          className="col-9 d-flex justify-content-center align-items-start"
          style={{ overflow: "auto" }}
        >
          {dashboardData?.slice(0, noOfKpis)?.map((e, index) => (
            <>
              <div
                className={`${classes.root}`}
                direction="row"
                spacing={1}
                onClick={() => getData(e.name, index)}
              >
                <div className="row d-flex justify-content-center align-items-center">
                  <div
                    className={`${classes.icon} col-3 d-flex justify-content-center align-items-center`}
                  >
                    <i
                      className={icons[index]}
                      style={{
                        fontSize: "20px",
                        color: iconColors[index],
                      }}
                    ></i>
                  </div>
                  <div
                    className={`col-9 d-flex justify-content-center align-items-center`}
                  >
                    <div className="row">
                      <h3 className="col-12">{e.value}</h3>
                      <h6 className="col-12">{e.name}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        <div className="col-3 p-3 d-flex justify-content-center align-items-start">
          <div className="row">
            <div className={`${classes.customCard} col-10 m-1`}>
              <div className={classes.sideCard}>
                <img src={kpiDoc} width={25} height={25} alt=""></img>
                {noOfKpis === 3 ? (
                  <span onClick={() => setNoOfKpis(10)}>View All KPIs</span>
                ) : (
                  <span onClick={() => setNoOfKpis(3)}>View Default KPIs</span>
                )}
              </div>
            </div>
            <Card className={`${classes.customCard} col-10 m-1`}>
              <div
                className={classes.sideCard}
                onClick={() => teamModelHander()}
              >
                <img src={teamsLogo} width={28} height={25} alt=""></img>
                <span> Team Members</span>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Grid container spacing={2} className={classes.graphCard}>
        <Grid item xs={6}>
          <Card className={`customCard ${classes.rootCard}`}>
            <h5
              style={{
                color: "black",
                fontWeight: "bold",
                // border: "0.3px solid #e0d5d5",
                padding: "10px 10px 0 10px",
                borderRadius: "14px",
                marginBottom: 0,
              }}
            >
              Work Commitment {"  "}{" "}
              <span
                style={{
                  color: "#707070",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                <i class="fa fa-info-circle" aria-hidden="true"></i> Definition
              </span>{" "}
              <i
                class="fa fa-download"
                style={{
                  color: "#5EBDCC",
                  float: "right",
                  cursor: "pointer",
                }}
                onClick={handlePieDownload}
              ></i>
            </h5>
            <hr style={{ background: "#F2F2F2", opacity: 1 }} />
            <div ref={pieRef}>
              <WorkAlocationChart data={WorkCommitmentDat} />
              {/* <PieChart width={400} height={200} ref={pieRef}>
              <Pie
                data={transformedArray}
                cx={200}
                cy={200}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
                // onClick={handlePieClick}
              >
                {transformedArray.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
            </PieChart> */}
            </div>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            style={{ cursor: "pointer" }}
            className={`customCard ${classes.rootCard}`}
            onClick={() => setIsSprintSpillover(true)}
          >
            <h5
              // className="col-12"
              style={{
                color: "black",
                fontWeight: "bold",
                // border: "0.3px solid #e0d5d5",
                padding: "10px 10px 0 10px",
                borderRadius: "14px",
                marginBottom: 0,
              }}
            >
              Sprint Spillover{"  "}{" "}
              <span
                style={{
                  color: "#707070",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                <i class="fa fa-info-circle" aria-hidden="true"></i> Definition
              </span>
            </h5>
            <hr style={{ background: "#F2F2F2", opacity: 1 }} />
            <div style={{ marginTop: "50px" }}>
              {/* <BarChart data={data} layout="vertical" width={500} height={400}>
              <XAxis type="number" />
              <YAxis
                dataKey="name"
                type="category"
                axisLine={{ stroke: "#ffffff" }}
              />
              <Legend />

              <Bar
                dataKey="Work committed"
                fill="#5EBDCC"
                barSize={20}
                radius={[0, 10, 10, 0]}
              >
                {" "}
                <LabelList
                  dataKey="Work committed"
                  position="insideRight"
                  angle="0"
                  fill="white"
                />
              </Bar>
              <Bar
                dataKey="work not done"
                fill="#8DDBE8"
                barSize={20}
                radius={[0, 10, 10, 0]}
              >
                <LabelList
                  dataKey="work not done"
                  position="insideRight"
                  angle="0"
                  fill="white"
                />
              </Bar>
            </BarChart> */}
              <BarChart data={sprintSpelloverData} />
            </div>
          </Card>
        </Grid>
      </Grid>
      <div className="row m-4 d-flex justify-content-between">
        <div
          className="col-6 p-0 pt-3 m-0"
          // style={{
          //   backgroundColor: "white",
          //   border: "0.1px solid #f4f4f4",
          //   borderRadius: "14px",
          //   width: "49%",
          // }}
        ></div>
        <div className="col-6 p-0 pt-3 m-0"></div>
      </div>
      {/* isOpenTeam,setIsOpenTeam */}
      <CommonTablePopup
        title="Team Member List"
        open={isOpenTeam}
        setOpen={setIsOpenTeam}
        columns={teamList.columnsList}
        data={teamList.data}
      />
    </>
  );
};
export default SprintPieCharts;
