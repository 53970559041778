import { Box, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import WorkAlocationChart from "../../Project/projectDashboard/graphs/workAlocationChart";
import SelectField from "../../../components/select/selectField";
import SelectInvestmentProfile from "../../../components/select/selectInvestmentProfile";
import CheckSelect from "../../../components/select/CheckSelect";

const WorkCommitStatusAndSummary = ({
  open,
  setIsSummaryAndStatus,
  dataForSummary,
  setGetStatusSummary,
}) => {
  const [selectedSubStatus, setSelectedSubStatus] = useState([]);
  const style = {
    position: "absolute",
    // height: 400,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#ffffff",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = () => {
    setIsSummaryAndStatus(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: 1200 }}>
        <Box display={"flex"} justifyContent={"flex-end"} onClick={handleClose}>
          <CancelIcon />
        </Box>

        <Grid container mt={2}>
          <Grid item xs={6}>
            <h5 className="pb-4">Summary (Story Point)</h5>

            <WorkAlocationChart
              data={dataForSummary?.statusData}
              name={"project"}
              height="400px"
            />
          </Grid>

          <Grid item xs={6}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <h5 className="pb-4">Team (Story Point)</h5>
              <Box>
                <CheckSelect
                  names={dataForSummary?.statusList}
                  setValue={(value) => {
                    setSelectedSubStatus(value);
                    setGetStatusSummary(value);
                  }}
                  value={selectedSubStatus}
                />
              </Box>
            </Box>
            <WorkAlocationChart
              data={dataForSummary?.teamData}
              name={"team"}
              height="400px"
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default WorkCommitStatusAndSummary;
